// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BannerGoogleMaps from "../../../../../../styleguide/components/BannerGoogleMaps/BannerGoogleMaps.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EnzuLocationsJson from "./assets/enzu-locations.json";

var allLocationsJson = EnzuLocationsJson;

function EnzuBareMetalMap(props) {
  return JsxRuntime.jsx(BannerGoogleMaps.make, {
              title: "Enzu Bare Metal Regions and Availability",
              description: "Place servers where your users are. Options include the United States, Japan, Hong Kong, Europe and Australia.",
              allLocationsJson: allLocationsJson,
              color: "Default"
            });
}

var make = EnzuBareMetalMap;

var $$default = EnzuBareMetalMap;

export {
  allLocationsJson ,
  make ,
  $$default as default,
}
/* allLocationsJson Not a pure module */
